export type ThemeType =
  | 'subpage'
  | 'edition'
  | 'explore'
  | 'full-page'
  | 'default'

interface State {
  theme: ThemeType
  isEditing: boolean
}

export const useLayoutStore = defineStore('layout', {
  state: (): State => ({
    theme: 'default',
    isEditing: false
  }),
  actions: {
    setTheme(theme: ThemeType) {
      this.theme = theme
    },
    toggleIsEditing() {
      this.isEditing = !this.isEditing
    }
  }
})

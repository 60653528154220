import { UserRole } from '~/types/permissions'

export enum ROUTE {
  ROOT = '/',
  DASHBOARD = '/dashboard',

  LEGAL_REPORTS = '/legal-reports',

  EXPLORE = '/explore',
  BENCHMARK = '/benchmark',

  LOGIN = '/login',
  SIGNUP = '/signup',
  SIGNUP__CONFIRM = '/signup/confirm',
  RESET_PASSWORD = '/reset-password',
  RESET_PASSWORD__CHANGE = '/reset-password/change',

  SETTINGS = '/settings',
  SETTINGS__WORKSPACE__RENAMING = `${SETTINGS}/workspace/renaming`,
  SETTINGS__WORKSPACE__PREFERENCES = `${SETTINGS}/workspace/preferences`,
  SETTINGS__PERMISSIONS__USERS = `${SETTINGS}/users`,
  SETTINGS__PERMISSIONS__ROLES = `${SETTINGS}/permissions/access-roles`,
  SETTINGS__PERMISSIONS__SENSITIVITY = `${SETTINGS}/permissions/data-sensitivity`,
  SETTINGS__PERMISSIONS__SOURCE_OWNERSHIP = `${SETTINGS}/permissions/source-ownership`,
  SETTINGS__INTEGRATIONS__SOURCES = `${SETTINGS}/integrations`,
  SETTINGS__INTEGRATIONS__MAPPING = `${SETTINGS}/data-mapping`,
  SETTINGS__INTEGRATIONS__NORMALIZATION = `${SETTINGS}/data-normalization`,
  SETTINGS__INTEGRATIONS__DATA_HEALTH = `${SETTINGS}/data-health`,
  SETTINGS__MY_ACCOUNT__PREFERENCES = `${SETTINGS}/my-account/preferences`,
  SETTINGS__MY_ACCOUNT__PROFILE = `${SETTINGS}/my-account/profile`
}

const MAIN_FEATURE_ROUTES: ROUTE[] = [
  ROUTE.DASHBOARD,
  ROUTE.BENCHMARK,
  ROUTE.EXPLORE,
  ROUTE.LEGAL_REPORTS
]

export const PAGE_ACCESS_BY_ROLE: {
  [key in UserRole]: ROUTE[]
} = {
  [UserRole.ADMIN]: [...MAIN_FEATURE_ROUTES, ROUTE.SETTINGS],
  [UserRole.RECRUITER]: [
    ...MAIN_FEATURE_ROUTES,
    ROUTE.SETTINGS__MY_ACCOUNT__PREFERENCES,
    ROUTE.SETTINGS__MY_ACCOUNT__PROFILE,
    ROUTE.SETTINGS__WORKSPACE__RENAMING
  ],
  [UserRole.FINANCE]: [
    ...MAIN_FEATURE_ROUTES,
    ROUTE.SETTINGS__MY_ACCOUNT__PREFERENCES,
    ROUTE.SETTINGS__MY_ACCOUNT__PROFILE,
    ROUTE.SETTINGS__WORKSPACE__RENAMING
  ],
  [UserRole.HR]: [
    ...MAIN_FEATURE_ROUTES,
    ROUTE.SETTINGS__MY_ACCOUNT__PREFERENCES,
    ROUTE.SETTINGS__MY_ACCOUNT__PROFILE,
    ROUTE.SETTINGS__WORKSPACE__RENAMING
  ],
  [UserRole.MANAGER]: [
    ...MAIN_FEATURE_ROUTES,
    ROUTE.SETTINGS__MY_ACCOUNT__PREFERENCES,
    ROUTE.SETTINGS__MY_ACCOUNT__PROFILE,
    ROUTE.SETTINGS__WORKSPACE__RENAMING
  ],
  [UserRole.EMPLOYEE]: [
    ROUTE.LEGAL_REPORTS,
    ROUTE.SETTINGS__MY_ACCOUNT__PREFERENCES,
    ROUTE.SETTINGS__MY_ACCOUNT__PROFILE
  ],
  [UserRole.NONE]: []
}

export const SOURCE_PERMISSION_PAGE_ACCESS: ROUTE[] = [
  ROUTE.SETTINGS__INTEGRATIONS__SOURCES,
  ROUTE.SETTINGS__INTEGRATIONS__MAPPING,
  ROUTE.SETTINGS__INTEGRATIONS__NORMALIZATION
]

export const HOME_PAGE_BY_ROLE: {
  [key in UserRole]: ROUTE
} = {
  [UserRole.ADMIN]: ROUTE.DASHBOARD,
  [UserRole.RECRUITER]: ROUTE.DASHBOARD,
  [UserRole.FINANCE]: ROUTE.DASHBOARD,
  [UserRole.HR]: ROUTE.DASHBOARD,
  [UserRole.MANAGER]: ROUTE.DASHBOARD,
  [UserRole.EMPLOYEE]: ROUTE.LEGAL_REPORTS,
  [UserRole.NONE]: ROUTE.ROOT
}

export enum QUERY_PARAMETERS {
  EXPLORER_MEASURE = 'measure',
  EXPLORER_SECOND_MEASURE = 'secondMeasure',
  EXPLORER_TYPE = 'type',
  EXPLORER_DIMENSIONS = 'dimensions',
  EXPLORER_GROUPS = 'groups',
  EXPLORER_SORT = 'sort',
  EXPLORER_COLUMNS = 'columns',
  EXPLORER_ORDERED_COLUMNS = 'orderedColumns',
  EXPLORER_PAGE = 'page',
  EXPLORER_TOTAL = 'total',
  EXPLORER_FIRST_10 = 'first10',

  DASHBOARD_SCOPE_TYPE = 'scopeType',
  DASHBOARD_FILTERS = 'filters',
  DASHBOARD_DEFAULT_FILTER_IDS_TO_REMOVE = 'defaultFilterIdsToRemove',
  START_DATE = 'start',
  END_DATE = 'end',

  LEGAL_REPORT_SECTION_ID = 'sectionId',

  SOURCE_VALUE_DIMENSION = 'sourceValueDimension',
  NORMALIZATION_FIELD = 'normalizationField',

  BENCHMARKER_SEGMENTS = 'segments',

  AUTH_WITH_DISCLAIMER = 'withDisclaimer',
  AUTH_CODE = 'code',

  REDIRECT_URL = 'redirectURL'
}
